import './SearchEnginePage.css'
import React, { useState } from 'react';
import Navbar from '../../components/utils/Navbar';
import Footer from '../../components/utils/Footer';
import { theme } from '../../theme/ThemeUtils';
import axios from 'axios';
import { environment } from '../../environments/environment';
import {
    ChakraProvider,
    Container,
    FormControl,
    Textarea,
    FormHelperText,
    Button,
    Box,
    Text,
    Heading,
    Link,
    useToast
} from '@chakra-ui/react';

const SearchEnginePage = () => {

    const toast = useToast();
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);

    const handleSubmit = async () => {
        const toastId = toast({
            title: 'Request sent for analysis.',
            description: "We're gathering insights.",
            status: 'loading',
            duration: null,
            isClosable: false,
        });

        const query = document.querySelector('#query-description').value

        console.log('Form sent: ', query)

        try {
            // Replace this with your axios request
            const response = await axios.post(environment.serverUrl + '/researcher/search', {
                "query": query
            });
            if (response.status == 200) {
                console.log(response.data);
                const filtered = response.data.filter((res) => res.search_results.results.length > 0);
                setResults(filtered);
                toast.update(toastId, {
                    title: 'Analysis complete',
                    description: "Your report is ready.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
            // const searchResults = [
            //     {
            //         "search_results": {
            //             "results": [
            //                 {
            //                     "classification": "highlight",
            //                     "quote": "Keyword research is the process of finding and analyzing search terms that people enter into search engines with the goal of using that data for a specific purpose, often for search engine optimization (SEO) or general marketing."
            //                 },
            //                 {
            //                     "classification": "highlight",
            //                     "quote": "Keyword research can uncover queries to target, the popularity of these queries, their ranking difficulty, and more."
            //                 }
            //             ]
            //         },
            //         "source": "https://blog.hubspot.com/marketing/how-to-do-keyword-research-ht"
            //     },
            //     {
            //         "search_results": {
            //             "results": [
            //                 {
            //                     "classification": "highlight",
            //                     "quote": "Keyword research is the process of finding and analyzing search terms that people enter into search engines with the goal of using that data for a specific purpose, often for search engine optimization (SEO) or general marketing."
            //                 },
            //                 {
            //                     "classification": "highlight",
            //                     "quote": "Keyword research can uncover queries to target, the popularity of these queries, their ranking difficulty, and more."
            //                 }
            //             ]
            //         },
            //         "source": "https://blog.hubspot.com/marketing/how-to-do-keyword-research-ht"
            //     },
            //     {
            //         "search_results": {
            //             "results": [
            //                 {
            //                     "classification": "highlight",
            //                     "quote": "Keyword research is the process of finding and analyzing search terms that people enter into search engines with the goal of using that data for a specific purpose, often for search engine optimization (SEO) or general marketing."
            //                 },
            //                 {
            //                     "classification": "highlight",
            //                     "quote": "Keyword research can uncover queries to target, the popularity of these queries, their ranking difficulty, and more."
            //                 }
            //             ]
            //         },
            //         "source": "https://blog.hubspot.com/marketing/how-to-do-keyword-research-ht"
            //     }
            // ];

        } catch (error) {
            toast.update(toastId, {
                title: 'Request failed',
                description: 'There was an error with your request.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <ChakraProvider theme={theme}>
            <div className='app-search-page'>
                <Navbar />
                <div className='search-engine-container'>
                    <Container width={'inherit'} mt="10" display={'flex'} gap={40} justifyContent={'center'} alignItems={'center'}>
                        {/* Search Input Section */}
                        <FormControl id="query-description">
                            <Textarea
                                width={'30vw'}
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder="Provide a detailed description of what you are searching for..."
                                rows={4}
                                shadow="sm"
                                focusBorderColor="brand.400"
                                fontSize={{
                                    sm: 'sm',
                                }}
                            />
                        </FormControl>
                        <Button minWidth={'100px'} onClick={handleSubmit} mt="4" colorScheme="blue">
                            Search
                        </Button>
                    </Container>

                    {/* Search Results Section */}
                    <Container  className='results-container' mt="6">
                        {results.length > 0 ? (
                            results.map((res, index) => (
                                <Box
                                    key={index}
                                    className='result-container'
                                    p="4"
                                    shadow="md"
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    mb="4"
                                    bg="gray.50"
                                >
                                    <Heading fontSize="lg" mb="2">
                                        Search Results from: 
                                        <br/>
                                        <Link href={res.source} color="blue.500" isExternal>{res.source}</Link>
                                    </Heading>
                                    <br/>
                                    {res.search_results.results.map((result, i) => (
                                        <Box key={i} mb="4">
                                            <Heading fontSize="md" mb="2">
                                                {result.classification === 'highlight' ? 'Highlight' : 'Result'}
                                            </Heading>
                                            <Text>{result.quote}</Text>
                                        </Box>
                                    ))}
                                </Box>
                            ))
                        ) : (
                            <Text>No results to display</Text>
                        )}
                    </Container>
                </div>
                <Footer />
            </div>
        </ChakraProvider>
    )
}

export default SearchEnginePage