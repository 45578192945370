import React, { useEffect, useLayoutEffect, useState } from 'react'
import { ChakraProvider, VStack, Text, Heading } from '@chakra-ui/react';
import Navbar from "../../components/utils/Navbar";
import Footer from "../../components/utils/Footer";
import SimpleSidebar from '../../components/utils/side-bar';
import './Dashboard.css'
import { theme } from '../../theme/ThemeUtils';
import ResearchForm from './ResearchForm';

const DashboardPage = () => {
  const [pageContent, setPageContent] = useState(<div><h1>Test</h1></div>);

  const updateContentBasedOnHash = () => {
    const hash = window.location.hash;

    // if (hash.includes('markets') || hash == '') {
    //   setPageContent(<Markets />);
    // } else {
    //   setPageContent(<div>
    //     <h1>Under Construction</h1>
    //   </div>); 
    // }
    // let gMContainer = document.getElementById('market-container');
    // let contentContainer = document.getElementById('content-container');
    // let oContainer = document.getElementById('other-container');
    // if (hash.includes('') || hash == '') {
    //   gMContainer.style.display = 'initial';
    //   contentContainer.style.display = 'none'
    //   oContainer.style.display = 'none';
    // } 
  };

  // useEffect(() => {
  //   updateContentBasedOnHash();

  //   window.addEventListener('hashchange', updateContentBasedOnHash);

  //   return () => {
  //     window.removeEventListener('hashchange', updateContentBasedOnHash);
  //   };
  // }, []);

  return (
    <ChakraProvider theme={theme}>
      <div className='app-dashboard'>
        <Navbar></Navbar>
        <div className='research-form-container'>
          <ResearchForm />
        </div>
        <Footer />
      </div>
    </ChakraProvider>
  )
}

export default DashboardPage