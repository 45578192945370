import React, { Component } from 'react';
import HomePage from './pages/home/HomePage';
import SearchEnginePage from './pages/search-engine/SearchEnginePage';
import { AuthProvider } from './contexts/AuthContext';

class App extends Component {

  render() {
    return (
      <AuthProvider><SearchEnginePage/></AuthProvider>
    );
  }
}

export default App;
