import { Heading, SimpleGrid, Box, Button } from '@chakra-ui/react';
import { useRef } from 'react';

const ReportComponent = (props) => {
    const reportContentRef = useRef(null);

    const reportContent = `
        ${props.getFormTracker()?.["companyName"]} DD1 Report:
        This is the detailed report data...
        <br />
        ${props.getReport()}
    `;

    const handleDownload = () => {
        const element = document.createElement("a");
        const file = new Blob([reportContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${props.getFormTracker()?.["companyName"]}_DD1_Report.txt`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element);
    };

    return (
        <>
            <Heading w="100%" textAlign={'center'} fontWeight="normal">
                {props.getFormTracker()?.["companyName"]} DD1 Report
            </Heading>

            <SimpleGrid mt={'2%'} columns={1} spacing={6} maxH={'70%'}>
                <Box
                    overflowY={'scroll'}
                    ref={reportContentRef}
                    p={4}
                    shadow="md"
                    height="300px" 
                    borderWidth="1px"
                >
                    {reportContent}
                </Box>

                <Box>
                    <Button colorScheme="blue" onClick={handleDownload}>
                        Download Report
                    </Button>
                </Box>
            </SimpleGrid>

        </>
    );
};

export default ReportComponent;
