'use client'

import { useEffect, useState } from 'react'
import {
    Progress,
    Box,
    ButtonGroup,
    Button,
    Heading,
    Flex,
    FormControl,
    GridItem,
    FormLabel,
    Input,
    Select,
    SimpleGrid,
    InputLeftAddon,
    InputGroup,
    Textarea,
    FormHelperText,
    InputRightElement,
} from '@chakra-ui/react'
import axios from 'axios';

import { useToast } from '@chakra-ui/react'
import { environment } from '../../environments/environment';
import ReportComponent from './ReportComponent';

/*
{
  "companyName": "string",
  "targetCountry": "string",
  "industry": "string",
  "businessDescription": "string",
  "businessModel": "string",
  "additionalDetails": "string"
}
*/

const Form1 = (props) => {
    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)
    return (
        <>
            <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="2%">
                Company Info
            </Heading>
            <Flex>
                <FormControl mr="5%">
                    <FormLabel htmlFor="company-name" fontWeight={'normal'}>
                        Name
                    </FormLabel>
                    <Input id="company-name" placeholder="Apple" value={props.getFormTracker()?.['companyName']} onChange={(e) => props.handleFormUpdate("companyName", e.target.value)} />
                </FormControl>

                <FormControl as={GridItem} colSpan={[6, 3]}>
                    <FormLabel
                        htmlFor="country"
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                            color: 'gray.50',
                        }}>
                        Country / Region
                    </FormLabel>
                    <Select
                        id="country"
                        name="country"
                        autoComplete="country"
                        placeholder="Select option"
                        focusBorderColor="brand.400"
                        shadow="sm"
                        size="sm"
                        w="full"
                        rounded="md"
                        value={props.getFormTracker()?.['targetCountry']}
                        onChange={(e) => props.handleFormUpdate("targetCountry", e.target.value)}>
                        <option>United States</option>
                        <option>Canada</option>
                        <option>Mexico</option>
                    </Select>
                </FormControl>
            </Flex>
            {/* <FormControl mt="2%">
                <FormLabel htmlFor="industry" fontWeight={'normal'}>
                    Short Industry Description
                </FormLabel>
                <Input id="industry" placeholder="IT, Consumer Software, Hardware and Smartphones" />
                <FormHelperText>We&apos;ll never share your email.</FormHelperText>
            </FormControl> */}

            {/* <SimpleGrid columns={1} spacing={6}> */}
            <FormControl mt="2%" as={GridItem} colSpan={[3, 2]}>
                <FormLabel
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                        color: 'gray.50',
                    }}>
                    Website
                </FormLabel>
                <InputGroup size="sm">
                    <InputLeftAddon
                        bg="gray.50"
                        _dark={{
                            bg: 'gray.800',
                        }}
                        color="gray.500"
                        rounded="md">
                        https://
                    </InputLeftAddon>
                    <Input
                        type="tel"
                        placeholder="www.example.com"
                        focusBorderColor="brand.400"
                        rounded="md"
                        value={props.getFormTracker()?.['website']}
                        onChange={(e) => props.handleFormUpdate("website", e.target.value)}
                    />
                </InputGroup>
            </FormControl>

            <FormControl mt="2%" id="description">
                <FormLabel
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                        color: 'gray.50',
                    }}>
                    Business Description
                </FormLabel>
                <Textarea
                    placeholder="Lorem ipsum"
                    rows={12}
                    shadow="sm"
                    focusBorderColor="brand.400"
                    fontSize={{
                        sm: 'sm',
                    }}
                    value={props.getFormTracker()?.['businessDescription']}
                    onChange={(e) => props.handleFormUpdate("businessDescription", e.target.value)}
                />
                <FormHelperText>
                    Provide a detailed description of the company being sourced.
                </FormHelperText>
            </FormControl>
            {/* </SimpleGrid> */}
        </>
    )
}

const Form2 = (props) => {
    return (
        <>
            <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="2%">
                Business Model
            </Heading>
            <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                    htmlFor="business-type"
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                        color: 'gray.50',
                    }}>
                    Type
                </FormLabel>
                <Select
                    id="business-type"
                    name="business-type"
                    autoComplete="business-type"
                    placeholder="Select option"
                    focusBorderColor="brand.400"
                    shadow="sm"
                    size="sm"
                    w="full"
                    rounded="md"
                    value={props.getFormTracker()?.['businessType']}
                    onChange={(e) => props.handleFormUpdate("businessType", e.target.value)}>
                    <option>B2C</option>
                    <option>B2B</option>
                    <option>B2B2C</option>
                    <option>Other</option>
                </Select>
            </FormControl>
            <FormControl mt="2%" as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                    htmlFor="sector"
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                        color: 'gray.50',
                    }}>
                    Industry / Sector
                </FormLabel>
                <Select
                    id="sector"
                    name="sector"
                    autoComplete="sector"
                    placeholder="Select option"
                    focusBorderColor="brand.400"
                    shadow="sm"
                    size="sm"
                    w="full"
                    rounded="md"
                    value={props.getFormTracker()?.['industry']}
                    onChange={(e) => props.handleFormUpdate("industry", e.target.value)}>
                    <option>IT</option>
                    <option>Canada</option>
                    <option>Other</option>
                </Select>
            </FormControl>

            <FormControl mt="2%" id="value-proposition">
                <FormLabel
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                        color: 'gray.50',
                    }}>
                    Value Proposition Description
                </FormLabel>
                <Textarea
                    placeholder="Lorem ipsum"
                    rows={6}
                    shadow="sm"
                    focusBorderColor="brand.400"
                    fontSize={{
                        sm: 'sm',
                    }}
                    value={props.getFormTracker()?.['businessModel']}
                    onChange={(e) => props.handleFormUpdate("businessModel", e.target.value)}
                />
                <FormHelperText>
                    Provide a detailed description of the company's intended value proposition.
                </FormHelperText>
            </FormControl>
            <FormControl mt="2%" id="additional-detail">
                <FormLabel
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                        color: 'gray.50',
                    }}>
                    Additional details
                </FormLabel>
                <Textarea
                    placeholder="Lorem ipsum"
                    rows={3}
                    shadow="sm"
                    focusBorderColor="brand.400"
                    fontSize={{
                        sm: 'sm',
                    }}
                    value={props.getFormTracker()?.['additionalDetails']}
                    onChange={(e) => props.handleFormUpdate("additionalDetails", e.target.value)}
                />
                <FormHelperText>
                    Provide any additional details about the company for Duely to consider.
                </FormHelperText>
            </FormControl>
        </>
    )
}

const Form3 = (props) => {
    return (
        <>
            <ReportComponent getReport={props.getReport} getFormTracker={props.getFormTracker} />
        </>
    )
}

export default function ResearchForm() {
    const toast = useToast()
    const [step, setStep] = useState(1)
    const [progress, setProgress] = useState(50)
    const [requestStatus, setRequestStatus] = useState('loading')
    const [report, setReport] = useState('')


    const initialFormState = {
        companyName: '',
        targetCountry: '',
        website: '',
        businessDescription: '',
        businessType: '',
        industry: '',
        businessModel: '',
        additionalDetails: '',
    };

    const [form, setForm] = useState(initialFormState);

    const handleFormUpdate = (key, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [key]: value,
        }));
    };

    const getFormTracker = () => {
        return form;
    }

    const getReport = () => {
        return report;
    }

    const updateRequestStatus = (toastId) => {
        setTimeout(() => {
            setRequestStatus('success');
            toast.update(toastId, {
                title: 'Analysis complete',
                description: "Your report is ready.",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        }, 3000);
    };

    const handleSubmit = async () => {
        setStep(3)
        const toastId = toast({
            title: 'Request sent for analysis.',
            description: "We're processing your request.",
            status: 'loading',
            duration: null,
            isClosable: false,
        });

        console.log('Form sent: ', form)

        try {
            // Replace this with your axios request
            const response = await axios.post(environment.serverUrl + '/researcher/market-research', form);
            if (response.status == 200) {
                console.log(response);
                setReport(response.data);
                setRequestStatus('success');
                toast.update(toastId, {
                    title: 'Analysis complete',
                    description: "Your report is ready.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
            // updateRequestStatus(toastId); // Simulating the request
        } catch (error) {
            toast.update(toastId, {
                title: 'Request failed',
                description: 'There was an error with your request.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            {/* <Heading>Research with Duely</Heading> */}
            <Box
                borderWidth="1px"
                rounded="lg"
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                width={800}
                p={6}
                m="10px auto"
                as="form">
                <Progress hasStripe value={progress} mb="5%" mx="5%" isAnimated></Progress>
                {step === 1 ? <Form1 getFormTracker={getFormTracker} handleFormUpdate={handleFormUpdate} /> : step === 2 ? <Form2 getFormTracker={getFormTracker} handleFormUpdate={handleFormUpdate} /> : <Form3 getFormTracker={getFormTracker} getReport={getReport} />}
                <ButtonGroup mt="5%" w="100%">
                    <Flex w="100%" justifyContent="space-between">
                        <Flex>
                            <Button
                                onClick={() => {
                                    setStep(step - 1)
                                    setProgress(progress - 50)
                                }}
                                isDisabled={step === 1}
                                colorScheme="teal"
                                variant="solid"
                                w="7rem"
                                mr="5%">
                                Back
                            </Button>
                            <Button
                                w="7rem"
                                isDisabled={step === 2}
                                onClick={() => {
                                    setStep(step + 1)
                                    if (step === 3) {
                                        setProgress(100)
                                    } else {
                                        setProgress(progress + 50)
                                    }
                                }}
                                colorScheme="teal"
                                variant="outline">
                                Next
                            </Button>
                        </Flex>
                        {step === 2 ? (
                            <Button
                                w="7rem"
                                colorScheme="red"
                                variant="solid"
                                onClick={handleSubmit}>
                                Submit
                            </Button>
                        ) : null}
                    </Flex>
                </ButtonGroup>
            </Box>
        </>
    )
}