import React, { Component } from 'react';
import Hero from "./Hero";
import { ChakraProvider, VStack, Text } from '@chakra-ui/react';
import Navbar from "../../components/utils/Navbar";
import LogoTextSection from "../../components/utils/LogoTextSection";
import LargeWithNewsletter from './Footer';

import { useAuth } from '../../contexts/AuthContext';
import DashboardPage from '../../pages/dashboard/DashboardPage'
import LoginPage from '../auth/LoginPage';

// const HomePage = () => {
//   return (
//     <ChakraProvider>
//         <div className="App">
//           <Navbar></Navbar>
//           <VStack>
//             <Hero></Hero>
//             <LogoTextSection title='What do we do here ?'>
//               <Text color={'gray.500'}>
//                 This is a place where we explore new ways to look at market data.<br />
//                 Dimensionality reduction, clustering, and other techniques are used to get the big picture and depict the stock market as a whole.<br />
//                 Conventional <strong>market analysts</strong> might find here <strong>a new way to detect anomalies, signals and trends</strong> to leverage in their strategies.
//               </Text>
//             </LogoTextSection>
//             <LargeWithNewsletter />

//           </VStack>
//         </div>
//       </ChakraProvider>
//   )
// }

// src/Home.js

const HomePage = () => {
  const { currentUser, signInWithGoogle, signOut } = useAuth();

  console.log('current user: ', currentUser)

  return (
    <div>
      {currentUser ? (
        <DashboardPage />
        // <div>
        //   <h2>Welcome, {currentUser.email}</h2>
        //   <button onClick={signOut}>Sign Out</button>
        // </div>
      ) : (
        <LoginPage />
      )}
    </div>
  );
};

export default HomePage;