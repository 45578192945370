let production = undefined;

switch(process.env.NODE_ENV) {
    case 'production':
      production = true;
      break;
    case 'development':
    default:
      production = false;
}

export const environment = {

    production: production,
    serverUrl: `${production ? 'https://duely-api.certend.com/api' : 'http://localhost:9999/api'}`,
};